.mainContainer {
  color: #000;
  padding: 0rem 1rem;

  & h3 {
    font-family: 'Stardos Stencil', cursive;
    text-align: center;
    font-size: 45px;
    color: #000;
    max-width: 74%;
    margin: 0 auto;
    line-height: 60px;
    font-weight: 500;
    @media (max-width: 991px) {
      font-size: 35px;
    }
    @media (max-width: 575px) {
      margin-bottom: 15px;
      max-width: 100%;
      line-height: 34px;
      font-size: 25px;
    }
  }

  & h4 {
    font-weight: 700;
    margin: 10px 0px 0px;
    font-size: 16px;
  }

  & .pricing {
    color: #c93b55;
  }
}

.subHeading {
  display: block;
  width: 100%;
  max-width: 580px;
  margin: 8px auto 20px;
  line-height: 1.5rem;
  text-align: center;
}

.businessForm {
  width: 100%;
  background: linear-gradient(-90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  max-width: 856px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 28px auto 0px;
  padding: 4px;
  border-radius: 32px;

  & .formWrapper {
    background: #022336;
    width: 100%;
    border-radius: 32px;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  & label {
    color: #fff;
    margin-top: 12px;
    position: relative;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & .usingActivityTimeMsg {
    font-size: 24px;
  }
  & > button {
    width: 75%;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0;
    background: linear-gradient(-90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    color: #fff;
    border-radius: 4px;

    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    }
  }

  & input {
    border: 0;
    background-color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
    margin-top: 12px;
    color: #000;
    height: 42px;
  }

  /* & select {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-sizing: border-box;
    outline: none;
    padding-right: 52px;
  } */
  /* & .selectLabel {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 52px;
      height: 38px;
      background-color: #fff;
      bottom: 3px;
      right: 1px;
      z-index: 1;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      pointer-events: none;
    }
    &::before {
      content: '';
      position: absolute;
      border: 9px solid transparent;
      border-top-color: #aaa;
      width: 18px;
      height: 18px;
      bottom: 8px;
      right: 18px;
      z-index: 2;
      pointer-events: none;
    }
  }

  & select::-ms-expand {
    display: none;
  } */

  & .nameContainer {
    width: 75%;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

    & label {
      width: 47%;
    }
    & label:first-child {
      margin-left: 0px;
    }
    & label:last-child {
      margin-right: 0px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      & label {
        width: 100%;
      }
    }
  }

  & .pwdHelperContainer,
  & .numberHelperContainer,
  & .emailErrContainer,
  & .generalErrContainer {
    display: block;
    margin-top: 6px;
    margin-left: 2px;
    line-height: 1.25rem;
  }

  & .lastElement {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  & .generalErrContainer {
    margin-top: 10px;
    width: 75%;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
  }

  & .showHidePwd {
    position: absolute;
    top: 46px;
    right: 10px;
    cursor: pointer;

    & img {
      width: 24px;
      height: 18px;
    }
  }

  & .userTypeInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0px;
    padding-left: 40px;
    position: relative;

    & span {
      width: 20px;
      height: 20px;
      border: 1px solid #aaa;
      position: relative;
      cursor: pointer;

      &::after {
        content: '\2713';
        position: absolute;
        top: 1px;
        left: 1px;
        width: 16px;
        height: 16px;
        background-color: transparent;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        display: none;
      }
    }

    & input {
      /* display: none; */
      position: absolute;
      width: 20px;
      height: 20px;
      margin-top: 0px;
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
      z-index: 4;
      opacity: 0;
      cursor: pointer;
      /* visibility: hidden; */
    }

    & input:checked + span:after {
      display: flex;
    }

    & label {
      margin: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}
.signuptncs {
  color: #fff;
}
