.mainContainer {
  color: #000;
  padding: 0rem 1rem;

  & h3 {
    width: 100%;
    text-align: center;
    max-width: 480px;
    font-size: 36px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
    margin: 48px auto 10px;
    @media (max-width: 991px) {
      font-size: 35px;
    }
    @media (max-width: 575px) {
      margin-bottom: 15px;
      max-width: 100%;
      line-height: 34px;
      font-size: 25px;
    }
  }

  & h4 {
    font-weight: 700;
    margin: 10px 0px 0px;
    font-size: 16px;
  }

  & .pricing {
    color: #c93b55;
  }
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 586px;
  margin: 6px auto;

  & .questionWrapper {
    display: flex;
    flex-direction: column;
    background-color: #ddd;
    border: 1px solid #ccc;
    padding: 12px 20px;
    position: relative;
    border-radius: 4px;
    margin: 6px 0px;

    & button {
      position: absolute;
      top: 6px;
      right: 4px;
      width: 36px;
      height: 36px;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 0;
      outline: none;
      transform: rotate(90deg);
    }

    & .question {
      font-size: 18px;
      font-weight: 600;
      margin-right: 24px;
    }

    & .answer {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.25rem;
      height: 0px;
      transform: scale(0);
    }

    & .activeAnswer {
      margin-top: 10px;
      height: auto;
      transform: scale(1);
    }
  }
}

.btndv {
  margin-top: 70px;
  margin-bottom: 150px;
  & a {
    display: block;
    cursor: pointer;
    margin: auto;
    border: 0;
    padding: 0;
    text-decoration: underline;
    color: #c93b54;
    font-weight: 700;
    width: 110px;
    &:hover {
      text-decoration: none;
    }
  }
}
